/* You can add global styles to this file, and also import other style files */
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "~jsoneditor/dist/jsoneditor.min.css";

@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/base16-light';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.compactCol {
  padding-left: 18px !important;
  padding-right: 0 !important;
}


.tag-{
  background-color: #ddd;
}

.tag-poi_category{
  background-color:orange;
}
.tag-charging_station{
  background-color: pink;
}
.tag-age{
  color:white;
  background-color: green;
}
.tag-historic{
  background-color: blue;
  color:white;
}

.tag-museum{
  background-color:#666;
  color:white;
}

.tag-natural{
  background-color:greenyellow;
}

.tag-railway{
  background-color:#9E9E9E;
  color:white;
}

.tag-tourism{
  background-color: aquamarine;
}

.ag-row .ag-cell {
  display: flex;
  // justify-content: center; /* align horizontal */
  align-items: center;
}
/* Global scrollbar */

/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #9E9E9E;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #f1f1f1;
}

/* Global toolbar */
mat-toolbar {
  background-color: white !important;
}

/* Global dividers */
.vertical-divider {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  height: 100%;
  width: 0;
}

/* Legacy */

.spacer {
  flex: 1 1 10px;
}

.mini-spacer {
  flex: 0 0 15px;
}

.ag-theme-alpine {
  @include ag-theme-alpine(( // use theme parameters where possible
      row-height: ag-derived(grid-size, $times: 3, $plus: 1),
      header-height: ag-derived(grid-size, $times: 3, $plus: 1),
    ));
}

.row {
  display: flex;
  flex-direction: row;
}


.column {
  display: flex;
  flex-direction: column;
}

.ag-popup {
  background-color: white !important;
  z-index: 99999 !important;
}

.fa-stack {
  left: -12px;
  top: 3px;
  width: initial;
}


.sidenav{
  background-color: #4b2055 !important;
  color: white !important;

  .mat-subheader {
    color:white !important;
  }

  .mat-list-item {
    color:white !important;
  }

  .mat-divider {
    border-color: #666;
  }
}

.mat-badge-content, .mat-fab{
  background-color: #d15957 !important;
  color: white !important;
}


.mat-progress-bar{
  background-color: #d15957 !important;
}

.mobile{
  .hide-mobile{
    display: none !important;
  }
}

.desktop{
  .hide-desktop{
    display: none !important;
  }
}

.ag-filter {
  background-color: #fff!important;
}